import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApSupplierService {


    list() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_supplier/list_supplier", form);

    }
    
    insert_supplier(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        //console.log(form);
        return axios.post("ap_supplier/insert_supplier", form);

    }

    update_supplier(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        //console.log(form);
        return axios.post("ap_supplier/update_supplier", form);

    }


    view_supplier(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        //console.log(form);
        return axios.post("ap_supplier/view_supplier", form);

    }

  
        

  
}

export default new ApSupplierService();